import Swiper from 'swiper/bundle';

var card_swipers = document.querySelectorAll('.section-cards .swiper');
var banner_swipers = document.querySelectorAll('.section-banner-slider .swiper');

var slide_to_btns = document.querySelectorAll('[data-swiper-slide-to]');

[].forEach.call(card_swipers, function(slider) {
  var $delay = 4000;
  var $speed = 1200;
  var $loop = true;
  var $preload = false;
  var $pagination = false;
  var $navigation = false;
  var $scrollbar = false;
  var $mousewheel = false;
  var $centered_slides = false;
  var $crossfade = true;
  var $items = 1;
  var $items_per_view = $items;
  var $initial_slide = 0;
  var $effect = 'slide';
  var $spacer = 0;
  var $effect_settings;
  var $disable_autoplay_on_interaction = true;

  var swiper_pagination = slider.parentNode.querySelector('.swiper-pagination');
  var swiper_prev = slider.closest('section').querySelector('.swiper-button-prev');
  var swiper_next = slider.closest('section').querySelector('.swiper-button-next');

  var swiper_items = slider.getAttribute('data-swiper-items');
  var swiper_items_per_group = slider.getAttribute('data-swiper-items-per-group');
  var swiper_effect = slider.getAttribute('data-swiper-effect');
  var swiper_initial_slide = slider.getAttribute('data-swiper-initial-slide');
  var swiper_center_slides = slider.getAttribute('data-swiper-center-slides');
  var swiper_scrollbar = slider.getAttribute('data-swiper-scrollbar');
  var swiper_spacer = slider.getAttribute('data-swiper-spacer');
  var swiper_autoplay = slider.getAttribute('data-swiper-autoplay');
  var swiper_mousewheel = slider.getAttribute('data-swiper-mousewheel');
  var swiper_loop = slider.getAttribute('data-swiper-loop');
  var swiper_crossfade = slider.getAttribute('data-swiper-crossfade');
  var swiper_speed = slider.getAttribute('data-swiper-speed');
  var swiper_delay = slider.getAttribute('data-swiper-delay');
  var swiper_preload = slider.getAttribute('data-swiper-preload');
  var swiper_disable_autoplay_on_interaction = slider.getAttribute('data-swiper-autoplay-disable');


  if (swiper_scrollbar == 'true') {
    $scrollbar = {
      el: slider.querySelector('.swiper-scrollbar'),
      draggable: true,
    };
  }

  if (swiper_speed != null) {
    $speed = parseInt(swiper_speed);
  }


  if (swiper_delay != null) {
    $delay = swiper_delay;
  }

  var $autoplay =  {
    disableOnInteraction: $disable_autoplay_on_interaction,
    delay: parseInt($delay),
  };

  if (swiper_autoplay == 'false') {
    $autoplay = false;
  }

  if (swiper_crossfade == 'false') {
    $crossfade = false;
  }

  if (swiper_disable_autoplay_on_interaction == 'true' ) {
    $disable_autoplay_on_interaction = true;
  }

  if (swiper_effect != null || swiper_effect != undefined) {
    $effect = swiper_effect;
  }

  if (swiper_prev != null || swiper_next != undefined) {
    $navigation = {
        nextEl: swiper_next,
        prevEl: swiper_prev
      };  
  }
  if (swiper_pagination != null || swiper_pagination != undefined) {
    $pagination = {
        el: swiper_pagination,
        type: 'bullets',
        clickable: true,
        renderBullet: function (index, className) {
          return '<span class="' + className + '" data-paginate="'+parseInt(index + 1)+'"></span>';
        },
    };
  }
  if (swiper_items != null || swiper_items != undefined) {
    $items = parseInt(swiper_items);
  }

  if (swiper_initial_slide != null || swiper_initial_slide != undefined) {
    $initial_slide = parseInt(swiper_initial_slide) - 1;
  }
  if (swiper_center_slides == 'true') {
    $centered_slides = true;
  }
  if (swiper_mousewheel == 'true') {
    $mousewheel = true;
  }

  if (swiper_loop == 'false') {
    $loop = false;
  }

  if ($effect == 'fade') {
    $items = 1;
    $items_per_view = 1;
  }

  if (swiper_items_per_group != null) {
    $items_per_view = parseInt(swiper_items_per_group);
  } else {
    $items_per_view = 1;
  }

  if (swiper_spacer != null || swiper_spacer != undefined) {
    $spacer = parseInt(swiper_spacer);
  }


  const swiper = new Swiper(slider, {
    observer: true,
    observeParents: true,
    watchSlidesProgress: true,
    speed: $speed,
    loop: $loop,
    autoplay: $autoplay,
    slidesPerView: 'auto',
    initialSlide: $initial_slide,
    scrollbar: $scrollbar,
    preloadImages: $preload,
    centeredSlides: $centered_slides,
    pagination: $pagination,
    mousewheel: $mousewheel,
    spaceBetween: $spacer,
    grabCursor: true,
    navigation: $navigation,
    coverflowEffect: {
      rotate: 0,
      scale: 0.75,
      slideShadows: false,
      stretch: 30,
      depth: 1,
      opacity: 0.2
    },
    breakpoints: {
      992: {
        slidesPerView: $items,
        slidesPerGroup: $items_per_view,
      }
    },
    on: {
    },
    effect: $effect,
    fadeEffect: {
      crossFade: $crossfade
    },
  });
});

[].forEach.call(banner_swipers, function(slider) {
  var swiper_pagination = slider.parentNode.querySelector('.swiper-pagination');
  var swiper_prev = slider.closest('section').querySelector('.swiper-button-prev');
  var swiper_next = slider.closest('section').querySelector('.swiper-button-next');
  console.log(swiper_next);
  var $navigation = false;  
  if (swiper_prev != null || swiper_next != null) {
    $navigation = {
        nextEl: swiper_next,
        prevEl: swiper_prev
    };  
  }
  var $pagination = {
      el: swiper_pagination,
      type: 'bullets',
      clickable: true,
      renderBullet: function (index, className) {
        var slideTitle = slider.querySelectorAll('.swiper-slide')[index].getAttribute('data-title');
          return '<span class="' + className + '" data-paginate="'+parseInt(index + 1)+'"><span>'+slideTitle+'</span></span>';
      },
  };
  const swiper = new Swiper(slider, {
    observer: true,
    observeParents: true,
    watchSlidesProgress: true,
    speed: 2000,
    loop: false,
    autoplay: false,
    slidesPerView: 1,
    grabCursor: true,
    pagination: $pagination,
    navigation: $navigation,
    effect: 'fade',
    fadeEffect: {
      crossFade: true
    },
  });
});
