import lazySizes from 'lazysizes';
import gsap from "gsap/all";
import ScrollToPlugin from "gsap/ScrollToPlugin";
import ScrollTrigger from "gsap/ScrollTrigger";

let windowWidth = window.innerWidth;
let windowHeight = window.innerHeight;
const page = document.getElementById('page');
const header = document.getElementById('header');

const searchIcn = document.getElementById('searchIcn');
const btnShare = document.getElementById('share');
const btnBackToTop = document.getElementById('backToTop');
const btnSkip = document.getElementById('btnSkip');
const page_header = document.getElementById('page-banner');
const dropdownTogglers = document.querySelectorAll('#header .dropdown-toggle');
const footerTitleTogglers = document.querySelectorAll('#footer .footer-title');

let revealContainers = document.querySelectorAll(".container-split-blocks");

lazySizes.init();

gsap.registerPlugin(ScrollToPlugin);
gsap.registerPlugin(ScrollTrigger);
gsap.install(window);

import { Collapse, Dropdown, Tooltip } from 'bootstrap';

var collapseElementList = [].slice.call(document.querySelectorAll('.collapse'))
var collapseList = collapseElementList.map(function (collapseEl) {
  return new Collapse(collapseEl)
})

var dropdownElementList = [].slice.call(document.querySelectorAll('.dropdown-toggle'))
var dropdownList = dropdownElementList.map(function (dropdownToggleEl) {
  return new Dropdown(dropdownToggleEl)
})

var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
  return new Tooltip(tooltipTriggerEl)
})


if (typeof(searchIcn) != 'undefined' && searchIcn != null) {
  searchIcn.addEventListener('click', function(e) {
      var container = document.querySelector('.search-wrapper');
      var header = document.getElementById('header');
      if(container.classList.contains('active')){
          // clear input
          if (document.getElementById('s').value.length == 0) {
              e.preventDefault();
              container.classList.remove('active');
              header.classList.remove('is-search-open');
              gsap.to('#mainMenu', {autoAlpha: 1});
          }
      }
      else {
          e.preventDefault();
           if(window.innerHeight > window.innerWidth || window.innerWidth < 1200) {
            gsap.to('#mainMenu', {autoAlpha: 0,
              duration: 0.2,
            onComplete: function(){
              header.classList.add('is-search-open');
              container.classList.add('active');
            }});
          } else {
              header.classList.add('is-search-open');
              container.classList.add('active');
          }
      }
  });
}

if (typeof(btnShare) != 'undefined' && btnShare != null) {
  const share_tl = gsap.timeline({paused: true});
  share_tl.to( '#share-icons a', { 
    autoAlpha: 1,
    scale:1, 
    stagger: {
      from: "start",
      amount: 0.25
    }
  });

  btnShare.addEventListener('click', function(e) {
      e.stopPropagation();
      if (this.classList.contains('active')) {
          share_tl.reverse();
          this.classList.remove('active');
      } else {
          this.classList.add('active');
          share_tl.play();
      }
  });
}

ScrollTrigger.create({
  trigger: '#page',
  start: 'top top',
  toggleActions: "play complete reverse reset",
  onEnter: function(){
    gsap.set('#page', { paddingTop: header.outerHeight });
    header.classList.add('is-scrolled');
  },
  onLeaveBack: function() {
    gsap.set('#page', { paddingTop: 0 });
    header.classList.remove('is-scrolled');
  }
});


if (typeof(page_header) != 'undefined' && page_header != null) {
  page_header.classList.add('is-inview');  
}

if (typeof(btnBackToTop) != 'undefined' && btnBackToTop != null) {
  btnBackToTop.addEventListener('click', function() {
    gsap.to(window, {scrollTo: 0, duration: 2, ease: Elastic.out});
  });

  ScrollTrigger.create({
    trigger: '#page',
    start: 'top -120',
    scrub: true,
    toggleClass: {className: 'is-scrolled', targets: btnBackToTop}
  });
}

if (typeof(btnSkip) != 'undefined' && btnSkip != null) {
  btnSkip.addEventListener('click', function() {
    gsap.to(window, {scrollTo: '#skipped'});
  });

}

revealContainers.forEach((split_block_section) => {
  let image_reveal = split_block_section.querySelector(".reveal");
  let image = split_block_section.querySelector(".reveal img");

  if (image_reveal != null) {
    let tl = gsap.timeline({
      scrollTrigger: {
        trigger: split_block_section,
        start: 'center bottom',
        once: true
  //      toggleActions: "restart none none reset"
      }
    });

    tl.set(image_reveal, { autoAlpha: 1 });

    if (split_block_section.classList.contains('split-blocks-reverse')) {
      tl.from(image_reveal, {
        xPercent: 100,
        duration: 1.5,
        ease: Expo.inOut
      });
      tl.from(image, {
        xPercent: -100,
        scale: 1.3,
        delay: -1.5,
        duration: 1.5,
        ease: Sine.inOut
      });
    } else {
      tl.from(image_reveal, {
        xPercent: -100,
        duration: 1.5,
        ease: Expo.inOut
      });
      tl.from(image, {
        xPercent: 100,
        scale: 1.3,
        delay: -1.5,
        duration: 1.5,
        ease: Sine.inOut
      });

    }
  }
});

document.addEventListener('DOMContentLoaded', function(event) { 
  windowWidth = window.innerWidth;
});


function setDocHeight() {
  document.documentElement.style.setProperty('--vh', `${window.innerHeight/100}px`);
};

window.addEventListener('resize', function () {
  setDocHeight();
});

window.addEventListener('orientationchange', function () {
  setDocHeight();
});

setDocHeight();

if (typeof(dropdownTogglers) != 'undefined' && dropdownTogglers != null) {
  [].forEach.call(dropdownTogglers, function(dropdownToggle) {
    dropdownToggle.addEventListener('click', function(e) {
      e.preventDefault();
      e.stopPropagation();
      window.location = e.target.href;
    });
  });
}

if (typeof(footerTitleTogglers) != 'undefined' && footerTitleTogglers != null) {
  [].forEach.call(footerTitleTogglers, function(dropdownToggle) {
    dropdownToggle.addEventListener('click', function(e) {
      e.preventDefault();
      e.stopPropagation();
      if (dropdownToggle.classList.contains('active') === true) {
        dropdownToggle.classList.remove('active');
      } else {
        dropdownToggle.classList.add('active');        
      }
    });
  });
}